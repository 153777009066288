module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Open Sans","file":"https://fonts.googleapis.com/css?family=Montserrat&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es","ru","zh","nl","de","fr"],"siteUrl":"https://howerest.com","generateDefaultLanguagePage":true,"trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false,"wrapRootElement":true},"pages":[{"matchPath":"/:lang","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/:a/:b","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/:a/:b","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/:a/:b/:c","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/:a/:b/:c/:d","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/:a/:b/:c/:d/:e","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/:a/:b/:c/:d/:e/:f","getLanguageFromPath":true,"excludeLanguages":[]}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
